import React from "react"
import styled, { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  body {
        background: #111;
        color: #FFF;
	margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
       }
  html {
    box-sizing: border-box;
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
`

const Grid = styled.div`
  display: grid;
  height: 100vh;
  grid: 52px auto / 50px;
  grid-auto-flow: column;
  grid-auto-columns: 270px;
  grid-column-gap: 7px;
`
const Controls = styled.div`
  grid-area: 1/1/-1/1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  text-align: center;
`

const ControlsTop = styled.div``
const ControlsBottom = styled.div``
const ColHeader = styled.h3`
  color: #fff;
  background: #222;
  grid-row: 1;
  text-align: center;
  align-self: center;
  padding:15px 10px 10px 10px;
  margin: 0px;
  height:100%;
`
const Col = styled.section`
  color: #fff;
  background: #333;
  grid-row: 2;
  overflow-y: scroll;
`

const Tweet = styled.div`
display: grid;
grid: auto/60px auto;
  padding: 10px;
  border-bottom: solid 1px #444;
  > img {
    border-radius: 50%;
    grid-row: 1/-1;
  }
  > p, >div {
    margin-top: 0;
    grid-column: 2/-1;
  }
`
const TweetIcons = styled.div``

const Page = ({ location }) => (
  <Grid>
    <GlobalStyle />
    <Controls>
      <ControlsTop>
        <button>+</button>
        <button>=</button>
      </ControlsTop>
      <ControlsBottom>
        <button>00</button>
      </ControlsBottom>
    </Controls>
    <ColHeader>Col Header</ColHeader>
    <Col>
      <Tweet>
        <img src="http://via.placeholder.com/50x50" />
        <p>
          The next What Is? Video is going to be What Is ES6? Trying out some
          new style on this video, excited to see how it turns out.
        </p>
        <TweetIcons>
          <button>+</button>
          <button>-</button>
          <button>0</button>
        </TweetIcons>
      </Tweet>
    </Col>
    <ColHeader>Col Header</ColHeader>
    <Col>
      <Tweet>
        <img src="http://via.placeholder.com/50x50" />
        <p>Wow is this another tweet? How could this have happened!</p>
        <TweetIcons>
          <button>+</button>
          <button>-</button>
          <button>0</button>
        </TweetIcons>
      </Tweet>
      <Tweet>
        <img src="http://via.placeholder.com/50x50" />
        <p>Wow is this another tweet? How could this have happened!</p>
        <TweetIcons>
          <button>+</button>
          <button>-</button>
          <button>0</button>
        </TweetIcons>
      </Tweet>
      <Tweet>
        <img src="http://via.placeholder.com/50x50" />
        <p>Wow is this another tweet? How could this have happened!</p>
        <TweetIcons>
          <button>+</button>
          <button>-</button>
          <button>0</button>
        </TweetIcons>
      </Tweet>
      <Tweet>
        <img src="http://via.placeholder.com/50x50" />
        <p>Wow is this another tweet? How could this have happened!</p>
        <TweetIcons>
          <button>+</button>
          <button>-</button>
          <button>0</button>
        </TweetIcons>
      </Tweet>
      <Tweet>
        <img src="http://via.placeholder.com/50x50" />
        <p>Wow is this another tweet? How could this have happened!</p>
        <TweetIcons>
          <button>+</button>
          <button>-</button>
          <button>0</button>
        </TweetIcons>
      </Tweet>
      <Tweet>
        <img src="http://via.placeholder.com/50x50" />
        <p>Wow is this another tweet? How could this have happened!</p>
        <TweetIcons>
          <button>+</button>
          <button>-</button>
          <button>0</button>
        </TweetIcons>
      </Tweet>
      <Tweet>
        <img src="http://via.placeholder.com/50x50" />
        <p>Wow is this another tweet? How could this have happened!</p>
        <TweetIcons>
          <button>+</button>
          <button>-</button>
          <button>0</button>
        </TweetIcons>
      </Tweet>
    </Col>
    <ColHeader>Col Header</ColHeader>
    <Col />
  </Grid>
)

export default Page
